'use client';

import usePixalEvent from '@/services/event/usePixalEvent';
import Link from 'next/link';

interface LinkWrapperProps {
	children: any;
	href: string;
	className?: string;
	style?: any;
	data?: any;
}

const LinkWrapper = ({ children, href, className, style, data }: LinkWrapperProps) => {
	const { EventCategoryAction, EventBannerAction, EventProductBrandAction } = usePixalEvent();
	const onClick = () => {
		console.log('LinkWrapper', data);

		switch (data?.type) {
			case 'category':
				EventCategoryAction({
					id: data?.id,
					name: data?.name
				});
				break;
			case 'banner':
				EventBannerAction({ id: data?.id, name: data?.name });
				break;
			case 'brand':
				EventProductBrandAction({ id: data?.id, name: data?.name });
				break;
			default:
				break;
		}
	};

	return (
		<Link href={href || ''} className={className || ''} style={style || {}} onClick={onClick}>
			{children}
		</Link>
	);
};

export default LinkWrapper;
