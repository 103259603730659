'use client';

import usePixalEvent from '@/services/event/usePixalEvent';
import { checkIsValidColor } from '@/services/utils/helper';
import Link from 'next/link';

const BlockSeeAll = ({ title, link, color = null, data = {} as any }): any => {
	const { EventBlockAction } = usePixalEvent();
	const colorCode = checkIsValidColor(color) ? color : '#000';

	return (
		<Link
			href={link || '/'}
			onClick={() => {
				EventBlockAction({
					id: data?.block_id,
					name: data?.block
				});
			}}>
			<div
				className='text-20 text-grey900 fw-700 cursor-pointer hover-text-primary'
				style={{
					...(colorCode && { color: colorCode })
				}}>
				{title}
			</div>
		</Link>
	);
};

export default BlockSeeAll;
